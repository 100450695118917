;(function($) {

	$(document).ready(function() {


		var lazy_load_images = function(e, slick, image, imageSource) {
			$(image).parent().css({
				'background-image': 'url(' + imageSource + ')'
			});
		}

		$('.js-testimonials').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});

		$('.js-backgrounds').on('lazyLoaded', lazy_load_images);

		$('.js-backgrounds').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1000,
			fade: true,
			dots: false,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});

		$('.js-main-carousel').slick({
			autoplay: false,
			speed: 300,
			slidesToShow: 1,
			pauseOnFocus: false,
			pauseOnHover: false,
			arrows: false,
			dots: false,
			fade: true,
			centerMode: true,
			asNavFor: '.js-nav-carousel'
		});

		$('.js-nav-carousel').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.js-main-carousel',
			arrows: true,
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 5,
						centerMode: ($('.single-homes__main__carousel__item').length > 6) ? true : false,
					}
				}
			]
		});

	});

})(jQuery);