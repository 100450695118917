;(function($) {

	$(document).ready(function() {

		$(window).scroll(function() {

			var scroll = $(window).scrollTop();

			if (scroll >= 100) {
				$('html').addClass('header-scrolled');
			} else {
				$('html').removeClass('header-scrolled');
			}
		});
	});

})(jQuery);