/**	
 * https://github.com/maxlutzfl/googlemapsjqueryplugin
 */ 

(function($) {

	var snazzy = [
		{
			"featureType": "all",
			"elementType": "geometry.fill",
			"stylers": [
			{
				"weight": "2.00"
			}
			]
		},
		{
			"featureType": "all",
			"elementType": "geometry.stroke",
			"stylers": [
			{
				"color": "#9c9c9c"
			}
			]
		},
		{
			"featureType": "all",
			"elementType": "labels.text",
			"stylers": [
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
			{
				"color": "#f2f2f2"
			}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry.fill",
			"stylers": [
			{
				"color": "#ffffff"
			}
			]
		},
		{
			"featureType": "landscape.man_made",
			"elementType": "geometry.fill",
			"stylers": [
			{
				"color": "#ffffff"
			}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
			{
				"visibility": "off"
			}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
			{
				"saturation": -100
			},
			{
				"lightness": 45
			}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry.fill",
			"stylers": [
			{
				"color": "#eeeeee"
			}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#7b7b7b"
			}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.stroke",
			"stylers": [
			{
				"color": "#ffffff"
			}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
			{
				"visibility": "simplified"
			}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.icon",
			"stylers": [
			{
				"visibility": "off"
			}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
			{
				"visibility": "off"
			}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
			{
				"color": "#46bcec"
			},
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
			{
				"color": "#c8d7d4"
			}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#070707"
			}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.stroke",
			"stylers": [
			{
				"color": "#ffffff"
			}
			]
		}
	];

	var init_map = function() {
		var map_data = $('[data-google-map]').attr('data-google-map');
		map_data = JSON.parse(map_data);
		console.log(map_data.markers);

		$('[data-google-map]').bcore_google_maps({
			api_key: theme.googleapi,
			lat: 0,
			lng: 0,
			zoom: 12,
			marker_icon: theme.map_marker,
			marker_icon_w: 36,
			marker_icon_h: 46,
			styles: snazzy,
			cluster: false,
			markers: map_data.markers,
			infowindow_template: function(infowindow_data) {
				return ' \
					<div class="app-map-info-box"> \
						<img src="' + infowindow_data.image + '" alt="Listing" style=""> \
						<p><strong>' + infowindow_data.title + '</strong></p> \
					</div> \
				';
			},
			on_infowindow_open: function(cb) {  },
			on_infowindow_close: function(cb) {  },
			on_marker_click: function(cb) {  }
		});
	}

	if ( $('[data-google-map]').length > 0 ) {
		init_map();
	}

	var condos_map = function() {
		var markers = [];
		var condos = $('[data-mapdata]');

		for (var i = 0; i < condos.length; i++) {
			var condo_data = $(condos[i]).attr('data-mapdata');
			condo_data = JSON.parse(condo_data);

			console.log('precheck', condo_data);

			if ( condo_data.lat ) {
				markers.push( condo_data );
			}
		}

		console.log('postcheck', markers);

		$('.js-condos-map').bcore_google_maps({
			api_key: theme.googleapi,
			lat: 0,
			lng: 0,
			zoom: 13,
			marker_icon: theme.map_marker,
			marker_icon_w: 36,
			marker_icon_h: 46,
			styles: snazzy,
			cluster: false,
			markers: markers,
			infowindow_template: function(infowindow_data) {
				console.log(infowindow_data); 
				return ' \
					<div class="app-map-info-box"> \
						<img src="' + infowindow_data.image + '" alt="Listing" style=""> \
						<p><strong>' + infowindow_data.title + '</strong></p> \
						<p><a href="' + infowindow_data.link + '">See Details</a></p> \
					</div> \
				';
			},
			on_infowindow_open: function(cb) {  },
			on_infowindow_close: function(cb) {  },
			on_marker_click: function(cb) {  }
		});
	}

	if ( $('.js-condos-map').length > 0) {
		condos_map();
	}

})(jQuery);