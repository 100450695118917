(function($) {

	$('#js-navToggle, #mobileMenu-close, #mobileMenu-layer-close').click(function (e) {
		e.preventDefault();
		toggleNav();
	});

	var toggleNav = function() {
		var currentStatus = $('html').attr('data-mobile-menu-is');
		if (currentStatus === 'open') {
			$('html').attr('data-mobile-menu-is', 'closed');
		} else {
			$('html').attr('data-mobile-menu-is', 'open');
		}
	}

	var check = function() {

		$('html').attr('data-mobile-nav-status', '');

		var navContainer, navItems, containerWidth;
		navContainer = $('.site-navigation__expanded');

		if (navContainer.length > 0) {

			containerWidth = navContainer.outerWidth();
			navItems = $(navContainer.selector + ' > li');
			var sumOfItemWidths = 0;

			for (var i = 0, len = navItems.length; i < len; i++) {
				var item, width;
				item = $(navItems[i]);
				width = item.width();
				sumOfItemWidths += parseInt(width);
			}

			if (sumOfItemWidths > containerWidth) {
				$('html').attr('data-mobile-nav-status', 'mobile');
			} else {
				$('html').attr('data-mobile-nav-status', 'expanded');
			}
		}
	}

	setTimeout(function() {
		$(document).ready(check);
	}, 500);

	$(window).resize(check);

	var toggleSubMenu = function(e) {
		$(this).toggleClass('open');
		$(this).next('.sub-menu').slideToggle();
	}

	var setDowndownIcons = function() {
		var parents = $('#mobileMenu .menu-item-has-children');
		parents.each(function(i) {
			$(parents[i]).children('a').after('<div class="mobileMenu-dropdown"></div>');
		});
	}

	$(document).ready(setDowndownIcons);
	$(document).on('click', '.mobileMenu-dropdown', toggleSubMenu);

})(jQuery);