;(function($) {

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var initSkrollr = function() {
		if (!isMobile()) {
			var s = skrollr.init({
				forceHeight: false,
				smoothScrolling: true,
				smoothScrollingDuration: 200
			});
		} else {
			$('html').addClass('no-skrollr');
		}
	}

	$(document).ready(function() {

		initSkrollr();

		$('html').removeClass('no-js').addClass('js');

		$('.post-content a[href$=".jpg"]').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'image',
			gallery: {
				enabled: true
			}
		});

		$('.js-modal').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'inline',
			preloader: false,
			modal: false,
		});

		$(document).on('click', '.popup-modal-dismiss', function(e) {
			e.preventDefault();
			$.magnificPopup.close();
		});

	});

	var gform_2_cols = function() {
		var form = $('.gform_2_cols');
		var fields = form.find('.gform_fields > .gfield');
		var slices = [];

		for (var i = 0; i < fields.length; i++) {
			var field = $(fields[i]).get(0);

			if ( $(field).hasClass('gsection') ) {
				slices.push(i);
			}
		}

		for (var i = 0; i < slices.length; i++) {
			fields.slice(slices[i], slices[i + 1]).wrapAll('<ul class="gform-section-group"></ul>');
		}
	}

	$(document).ready(function() {
		gform_2_cols()
	});

	var openVideo = function() {
		$('.video-overlay__action').css({
			'display': 'none'
		});

		$('.next-open-house').css({
			'opacity': 0,
			'visibility': 'hidden'
		});

		$('.video-overlay__video').css({
			'display': 'block',
			'opacity': 0
		});

		var video = $('[data-video]').attr('data-video');
		$('.video-overlay__container').html(JSON.parse(video));

		setTimeout(function() {
			$('.video-overlay__video').css({
				'opacity': 1
			});
		}, 500);
	}

	var closeVideo = function() {
		$('.video-overlay__container').html(null);

		$('.video-overlay__action').removeAttr('style');
		$('.video-overlay__video').removeAttr('style');
		$('.next-open-house').removeAttr('style');
	}

	$('.video-overlay__action').click(function() {
		openVideo();
	});

	$('.video-overlay__close').click(function() {
		closeVideo();
	});

})(jQuery);