(function($) {

	$.fn.bcorePluginTemplate = function(args) {

		var instance = null;
		var options = {};

		var defaults = {
			int: 0,
			string: 'Hello',
			bool: false,
			callback: function() {  }
		}

		$.extend(options, defaults, args);

		var Plugin = {
			init: function(instance, options) {
				
			}
		}

		return this.each(function() {
			instance = $(this);
			Plugin.init(instance, options);
		});
	}

})(jQuery);